import {
  Notyf,
  type NotyfHorizontalPosition,
  type NotyfVerticalPosition,
} from "notyf";
import { isClient } from "~/helpers/env";

type NotyfInstance = Notyf | undefined;
interface ToastInstance {
  success: (text: string) => void;

  info: (text: string) => void;

  warning: (text: string) => void;

  error: (text: string) => void;
}

let notyf = undefined as NotyfInstance;

function initToast({
  x = "right",
  y = "top",
  duration = 4000,
}: {
  x?: NotyfHorizontalPosition;
  y?: NotyfVerticalPosition;
  duration?: number;
}) {
  if (!isClient) {
    return;
  }
  import("notyf/notyf.min.css");
  // Create an instance of Notyf
  notyf = new Notyf({
    position: { x, y },
    duration,
    types: [
      {
        type: "info",
        background: "#22d3ee",
        icon: false,
      },
      {
        type: "warning",
        background: "#eab308",
        icon: false,
      },
    ],
  });
}

const toast: ToastInstance = {
  success: function (): void {
    if (!isClient) return;

    throw new Error("Function not implemented.");
  },
  info: function (): void {
    if (!isClient) return;

    throw new Error("Function not implemented.");
  },
  warning: function (): void {
    if (!isClient) return;

    throw new Error("Function not implemented.");
  },
  error: function (): void {
    if (!isClient) return;
    throw new Error("Function not implemented.");
  },
};
if (isClient) {
  toast.success = function (text: string) {
    notyf?.dismissAll();
    const parsedText = typeof text === "string" ? text : "";
    if (parsedText.length) {
      notyf?.success(text);
    }
  };
  toast.error = (text: string) => {
    notyf?.dismissAll();
    const parsedText = typeof text === "string" ? text : "";
    if (parsedText.length) {
      notyf?.error(text);
    }
  };
  toast.info = (text: string) => {
    notyf?.dismissAll();
    const parsedText = typeof text === "string" ? text : "";
    if (parsedText.length) {
      notyf?.open({
        type: "info",
        message: text,
      });
    }
  };
  toast.warning = (text: string) => {
    notyf?.dismissAll();
    const parsedText = typeof text === "string" ? text : "";
    if (parsedText.length) {
      notyf?.open({
        type: "warning",
        message: text,
      });
    }
  };
}

export default toast;
export { initToast };
